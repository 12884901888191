import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes';
import Link from 'next/link';

// Utils
import { cn } from 'utils';
// Styles
import styles from './Content404.module.css';
// Constants
import { CONTACT_SLUG, URL_SEARCH_PAGES } from '../../../src/constants';
import Button from '../Button/Button';

function Content404({ theme, className }) {
  const { locale } = useRouter();
  const { t } = useTranslation();
  return (
    <div className={cn([styles.wrapper, styles[theme], className])}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div>
            <p className={styles.subtitle}>{t('404.subtitle')}</p>
            <h1 className={styles.title}>{t('404.title')}</h1>
          </div>
          <p className={styles.text}>{t('404.text')}</p>
          <div className={styles.links}>
            <span className={styles.linkText}>{t('404.links')}</span>
            <Link
              className={styles.link}
              href={`/${URL_SEARCH_PAGES[locale]}`}
            >
              {t('404.residencesColiving')}
            </Link>
            <Link
              className={styles.link}
              href={CONTACT_SLUG}
            >
              {t('404.contact')}
            </Link>
          </div>
          <Link
            href="/"
            locale={locale}
          >
            <Button className={styles.button} iconPosition="right" icon="arrowRight">
              {t('404.backHome')}
            </Button>
          </Link>
        </div>
        <div className={styles.imageContainer}>
          <Image
            src="/images/samples/404.webp"
            alt="404"
            height="656"
            width="895"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
}

Content404.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
};

Content404.defaultProps = {
  theme: 'dark',
  className: '',
};

export default Content404;
